import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
    Etusivu: "/",
    // Blogi: "/blog",
    Imprint: "fi/imprint"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/imprint"
);

  const Imprint = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title="Impressum"
          description="Tietoja verkkosivuston ylläpitäjästä sekä oikeudelliset huomautukset."
          lang="fi"
          alternateLangs={alternateLangs}
          meta={[{ name: "robots", content: "noindex" }]}
        />
        <MainContent article={false}>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
  
          <H as="h1">Impressum</H>
          <p>Verkkosivuston bluerivermountains.com ylläpitäjä:</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 Kööpenhamina
          <br />
          Tanska
        </p>
        <p className="baseline">
          Sähköposti: <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Puhelin: +45 93 86 85 64
        </p>
        <p className="baseline">Verotunnus: 39474263</p>
        <p>Tämän sivuston tekijänoikeudet kuuluvat Matthias Kupperschmidtille.</p>
        <H as="h2">Oikeudelliset tiedot</H>
        <p>
          Tarkistan säännöllisesti tämän verkkosivuston tiedot. Kaikesta huolellisuudesta huolimatta tiedot voivat olla muuttuneet
          ajan mittaan. Vastuu tai takuu tietojen ajantasaisuudesta, oikeellisuudesta ja täydellisyydestä ei siksi ole
          hyväksyttävissä. Sama koskee verkkosivustoja, joihin viitataan hyperlinkkien kautta. Matthias
          Kupperschmidt ei ole vastuussa näiden yhteyksien kautta saavutettujen verkkosivustojen sisällöstä. Tietojen tai
          tietojen jäljentäminen – erityisesti tekstien, tekstin osien tai kuvien käyttö – vaatii etukäteen annetun suostumuksen.
          Tämä koskee myös kaikkia blogikirjoituksia. Haluan huomauttaa, että tallennan kaikki hakukenttään syötetyt tiedot
          enkä käytä näitä tietoja omaan mainontaan enkä luovuta niitä kolmansille osapuolille.
        </p>
        <p>
          Lisätietoja löytyy <Link to="/fi/tietosuojakäytäntö">tietosuojaselosteestani</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Imprint;
